"use client";

import { FieldAttributes, useField, useFormikContext } from "formik";
import { forwardRef } from "react";

import Field from "@ui/js/components/form/field";

type Props = {
  hasAutoComplete?: boolean;
};

const LeadFormField = forwardRef<HTMLDivElement, FieldAttributes<any> & Props>(
  (props, ref) => {
    const [_, meta] = useField(props);
    const { status } = useFormikContext();
    const { hasAutoComplete = false, ...rest } = props;

    const error =
      meta.error ||
      (status === "error" &&
        "An error occurred while processing your request. Please try again.");

    const state = status || (error ? "error" : undefined);

    return (
      <Field
        ref={ref}
        {...rest}
        message={error}
        state={state}
        hasAutoComplete={hasAutoComplete}
      />
    );
  },
);

LeadFormField.displayName = "LeadFormField";

export default LeadFormField;
