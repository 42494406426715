import PropTypes from "prop-types";

type Props = {
  suggestion: string;
  selectSuggestion: () => void;
  isSelected: boolean;
  isActive: boolean;
};

const Suggestion = ({
  suggestion,
  selectSuggestion,
  isSelected,
  isActive,
}: Props) => {
  const formatAutocompleteSuggestion = (suggestion: any) => {
    const addressText = suggestion?.addressText
      ? `${suggestion.addressText} `
      : "";
    const street = suggestion.streetLine ? `${suggestion.streetLine}` : "";
    const secondary = suggestion?.secondary ? ` ${suggestion.secondary}` : "";
    const entries =
      suggestion?.entries > 1 ? ` (${suggestion.entries} more entries)` : "";
    const city = suggestion?.city ? `${suggestion.city}, ` : "";
    const state = suggestion?.state ? `${suggestion.state} ` : "";
    const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

    return (
      addressText + street + secondary + entries + ", " + city + state + zip
    );
  };

  /**
   * uses :hover (for mouse) pseudo-selector
   * to style the hover state of the suggestion
   * & :focus-visible (for keyboard only focus) psuedo-selector
   * to style the focus state of the suggestion
   */
  return (
    <li
      className="autocomplete--suggestion"
      /**
       * role="option" is used to indicate that the element
       * is an option for a selectable listbox
       */
      role="option"
      /**
       * WCAG compliantly, the aria-selected attribute is used
       * to indicate which suggestion is currently selected
       * @link https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-selected
       */
      aria-selected={isSelected}
      data-is-active={isActive}
      onClick={selectSuggestion}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          event.preventDefault();
          event.stopPropagation();
          selectSuggestion();
        }
      }}
    >
      {formatAutocompleteSuggestion(suggestion)}
    </li>
  );
};

Suggestion.propTypes = {
  suggestion: PropTypes.any,
  selectSuggestion: PropTypes.func.isRequired,
};

export default Suggestion;
