import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { forwardRef } from "react";

import Dialog from "@client/components/lead-form/components/dialog";

type Props = {
  children: React.ReactNode;
  dialog?: string;
  dialogHeader?: string;
  legend: string | React.ReactNode;
  className?: string;
};

const LeadFormFieldset = forwardRef<HTMLFieldSetElement, Props>(
  ({ children, dialog, dialogHeader, legend, className = "" }, ref) => {
    return (
      <fieldset className="form__fieldset" ref={ref}>
        <div className="form__fieldset__header">
          <legend className="form__legend">
            {typeof legend === "string" ? (
              <span className="form__legend__text">{legend}</span>
            ) : (
              legend
            )}
            {dialog && (
              <FontAwesomeIcon icon={faCircleInfo} height={12} width={12} />
            )}
          </legend>
          {dialog && <Dialog headerText={dialogHeader} text={dialog} />}
        </div>
        <div className={clsx("form__fieldset__body", className)}>
          {children}
        </div>
      </fieldset>
    );
  },
);

LeadFormFieldset.displayName = "LeadFormFieldset";

export default LeadFormFieldset;
