import clsx from "clsx";
import { useEffect, useState } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";

import Suggestion from "./suggestion";

const UNSELECTED_INDEX = -1;

type Props = {
  suggestions: SmartySDK.usAutocompletePro.Suggestion[];
  selectSuggestion: any;
  inputRef?: React.RefObject<HTMLInputElement>;
  isOpen: boolean;
  ariaControlsId: string;
};

const Suggestions = ({
  suggestions,
  selectSuggestion,
  inputRef,
  isOpen,
  ariaControlsId,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState(UNSELECTED_INDEX);
  const [activeIndex, setActiveIndex] = useState(UNSELECTED_INDEX);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // shift+tab to move focus to the input
      // essentionally unselects the suggestion
      if (e.key === "Tab" && e.shiftKey) {
        e.preventDefault();
        setSelectedIndex(UNSELECTED_INDEX);
        setActiveIndex(UNSELECTED_INDEX);
        selectSuggestion(suggestions[UNSELECTED_INDEX], true);
        inputRef?.current?.focus();
        return;
      }

      switch (e.key) {
        case "ArrowDown":
          // prevent default behavior of scrolling the page
          // prevent the cursor in input from moving
          e.preventDefault();
          e.stopPropagation();
          // jump to first suggestion if last suggestion is currently selected
          setActiveIndex((prev) => {
            if (prev === suggestions.length - 1) return 0;
            const nextSuggestion =
              prev < suggestions.length - 1 ? prev + 1 : prev;
            return nextSuggestion;
          });
          break;
        case "ArrowUp":
          // prevent default behavior of scrolling the page
          // prevent the cursor in input from moving
          e.preventDefault();
          e.stopPropagation();
          setActiveIndex((prev) => {
            if (prev === 0) return suggestions.length - 1;
            const nextSuggestion = prev > 0 ? prev - 1 : prev;
            return nextSuggestion;
          });
          break;

        case "Enter":
          e.preventDefault();
          e.stopPropagation();
          if (activeIndex >= 0) {
            selectSuggestion(suggestions[activeIndex]);
          }
          break;

        // spacebar
        case " ":
          if (activeIndex >= 0) {
            e.preventDefault();
            e.stopPropagation();
            selectSuggestion(suggestions[activeIndex]);
          }
          break;

        case "Escape":
          setSelectedIndex(UNSELECTED_INDEX);
          setActiveIndex(UNSELECTED_INDEX);
          inputRef?.current?.focus();
          break;

        case "Tab":
          // if the user presses tab, clear the selected index
          // and close the dropdown
          selectSuggestion(suggestions[UNSELECTED_INDEX], true);
          setSelectedIndex(UNSELECTED_INDEX);
          setActiveIndex(UNSELECTED_INDEX);

          inputRef?.current?.blur();
          break;
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    activeIndex,
    suggestions,
    selectSuggestion,
    setActiveIndex,
    setSelectedIndex,
  ]);

  return (
    <ul
      aria-label="autocomplete street address suggestions"
      className={clsx("autocomplete--suggestions", {
        "autocomplete--scrollbar": suggestions.length > 5,
      })}
      role="listbox"
      aria-expanded={isOpen}
      aria-controls={ariaControlsId}
      aria-live="polite"
    >
      {suggestions.map((suggestion: any, index: number) => (
        <Suggestion
          key={index}
          suggestion={suggestion}
          selectSuggestion={() => selectSuggestion(suggestion)}
          isSelected={index === selectedIndex}
          isActive={index === activeIndex}
        />
      ))}
    </ul>
  );
};

export default Suggestions;
