import {
  ValuesType,
  formatAutocompleteSuggestion,
} from "@packages/server/src/smarty/autocomplete";

// TODO: fix up this function - is currently messy and terrible
export function checkValidityOfFormValuesAgainstInput(
  formValuesInstance: ValuesType,
) {
  if (!formValuesInstance.streetAddress && formValuesInstance.oneLineAddress) {
    return false;
  }

  // mutate formValuesInstance to include the keys needed to run formatAutocompleteSuggestion
  const mutatedFormValuesInstance = {
    ...formValuesInstance,
    streetLine: formValuesInstance.streetAddress,
    zipcode: formValuesInstance.zipCode,
  };
  const formattedAddress = formatAutocompleteSuggestion(
    mutatedFormValuesInstance,
  );

  if (formattedAddress == formValuesInstance.oneLineAddress) {
    return true;
  }
  return false;
}
