"use client";

import { checkValidityOfFormValuesAgainstInput } from "@packages/server/src/smarty/addressValidation";
import {
  ValuesType,
  client,
  createUSLookup,
  formatAutocompleteSuggestion,
} from "@packages/server/src/smarty/autocomplete";
import Suggestions from "@packages/server/src/smarty/suggestions";
import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as SmartySDK from "smartystreets-javascript-sdk";

// import { SMARTY_EMBEDDED_KEY } from "@shared/constants";
import { ONE_LINE_ADDRESS_ERROR_MESSAGE } from "@shared/constants";

import kebabToCamelCase from "@shared/functions/kebabToCamelCase";
import sleep from "@shared/functions/sleep";

import postEvent from "@server/front-end-api/postEvent";

import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import { useCMSOverride } from "@client/functions/custom-hooks/useCMSOverride";

type Props = {
  legendOverride?: string;
};

const INPUT_ID_AND_LABEL_HTMLFOR = "one-line-address";
const FIELD_NAME_SELECTOR = kebabToCamelCase(INPUT_ID_AND_LABEL_HTMLFOR);

export default function Address({ legendOverride }: Props) {
  const [suggestions, setSuggestions] = useState<
    SmartySDK.usAutocompletePro.Suggestion[]
  >([]);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLFieldSetElement>(null);

  const { submitCount, setFieldValue, setStatus, submitForm, values } =
    useFormikContext();

  const formValues = values as ValuesType;

  // Grabs previous error message from previous submit for addressValidation function -- must be outside function because hooks can only be called top level
  const [oneLineAddressField, oneLineAddressMeta] =
    useField(FIELD_NAME_SELECTOR);

  // If the previous submit had an invalid formatted address
  const previousSubmitHadInvalidFormat =
    oneLineAddressMeta.error === ONE_LINE_ADDRESS_ERROR_MESSAGE;

  useEffect(() => {
    const handleClickOutside: EventListener = (event) => {
      if (
        event.target instanceof HTMLElement &&
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target)
      ) {
        setDisplaySuggestions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const legendFallback = "What is your address?";
  const legend = useCMSOverride(legendFallback, legendOverride);

  const queryAutocompleteForSuggestions = async (
    query: string,
    e: React.ChangeEvent<HTMLInputElement> | null,
    hasSecondaries = false,
  ) => {
    // always set the field value for whatever the input is, even if it's an empty string
    setFieldValue(FIELD_NAME_SELECTOR, query);

    // only run lookup if query is not an empty string
    if (query.length > 0) {
      const lookup = createUSLookup(query, hasSecondaries);

      try {
        const results = await client.send(lookup);
        setDisplaySuggestions(
          results && results?.result?.length > 0 ? true : false,
        );
        setSuggestions(results.result);
      } catch (error) {
        console.warn(error);
        postEvent("error", {
          error: {
            error_message:
              "Smarty lookup failed - see 'raw_error' in this error event object for more information",
            error_object: "Lead Form",
            error_type: "smarty_validation",
            path: window.location.href,
            raw_error: error,
          },
        });
      }
    }
  };

  const setFormValues = (address: SmartySDK.usAutocompletePro.Suggestion) => {
    setFieldValue(
      "streetAddress",
      (address.streetLine || "") +
        (address.secondary ? ` ${address.secondary}` : ""),
    );
    setFieldValue("city", address.city || "");
    setFieldValue("state", address.state || "");
    setFieldValue("zipCode", address.zipcode || "");
  };

  const clearFormValues = () => {
    setFieldValue("streetAddress", "");
    setFieldValue("city", "");
    setFieldValue("state", "");
    setFieldValue("zipCode", "");
  };

  const selectSuggestion = async (
    suggestion: SmartySDK.usAutocompletePro.Suggestion,
    bail: boolean = false,
  ) => {
    if (bail) {
      setDisplaySuggestions(false);
      return;
    }

    if (suggestion.entries > 1) {
      await queryAutocompleteForSuggestions(
        formatAutocompleteSuggestion(suggestion, true),
        null,
        true,
      );
    } else {
      setFormValues(suggestion);

      const formattedAddress = formatAutocompleteSuggestion(suggestion);
      setFieldValue(FIELD_NAME_SELECTOR, formattedAddress);

      if (inputRef.current) {
        inputRef.current.value = formattedAddress;
        inputRef.current.focus();
      }
      setDisplaySuggestions(false);
    }
  };

  const invalidAddressObject = {
    streetLine: "invalid",
    secondary: "",
    entries: 0,
    city: "invalid",
    state: "XX",
    zipcode: "12345",
  };

  function onSubmitAddress() {
    if (
      formValues.oneLineAddress &&
      !checkValidityOfFormValuesAgainstInput(formValues)
    ) {
      const lookup = createUSLookup(formValues.oneLineAddress, false);
      client
        .send(lookup)
        .then((results) => {
          return results.result;
        })
        .then((suggestions) => {
          if (
            suggestions.length === 1 &&
            formValues.oneLineAddress.includes(suggestions[0].zipcode)
          ) {
            setFormValues(suggestions[0]);
            const formattedAddress = formatAutocompleteSuggestion(
              suggestions[0],
            );
            postEvent("warning", {
              error: {
                error_message:
                  "Valid address provided, but no suggestion selected; valid address form values set via autocomplete verification",
                error_object: "Lead Form",
                error_type: "smarty_validation",
                path: window.location.href,
              },
            });
            setFieldValue(FIELD_NAME_SELECTOR, formattedAddress);
          } else {
            submitCount > 0
              ? setFormValues(invalidAddressObject)
              : clearFormValues();
          }
        })
        .then(() => sleep(300))
        .then(() => submitForm());
    } else {
      submitForm();
    }
  }

  async function addressValidation() {
    // The error message to display to the user
    let errorMessage;

    // If submit count is <0 and the previous submit did not have an invalid format, do not show validation error and let field be submitted
    if (
      submitCount > 0 &&
      !previousSubmitHadInvalidFormat &&
      formValues.oneLineAddress
    ) {
      if (formValues.streetAddress == "invalid") {
        // TODO: make this it's own event, not an error
        postEvent("warning", {
          error: {
            error_message: `User has successfully submitted an address not verified by Smarty Autocomplete`,
            error_object: "Lead Form",
            error_type: "smarty_validation",
            path: window.location.href,
            invalid_address: formValues.oneLineAddress,
          },
        });
        return ""; // No validation error
      }
      if (!formValues.streetAddress) {
        postEvent("error", {
          error: {
            error_message: `Internal parsing has submitted a blank street address`,
            error_object: "Lead Form",
            error_type: "smarty_validation",
            path: window.location.href,
            invalid_address: formValues.oneLineAddress,
          },
        });
        setStatus("warning");
        errorMessage =
          "The entered address appears to be invalid.  Please verify it is correct.";
      }
    }

    // Reset the lead form status via Formik
    setStatus("");

    // If the streetAddress field is populated, return no error
    if (formValues.streetAddress) {
      return "";
    } else if (!formValues.oneLineAddress) {
      // Set the lead form status to "error" via Formik
      setStatus("error");
      postEvent("error", {
        error: {
          error_message: `User has submitted a blank address form`,
          error_object: "Lead Form",
          error_type: "smarty_validation",
          path: window.location.href,
        },
      });

      errorMessage = ONE_LINE_ADDRESS_ERROR_MESSAGE;
      return errorMessage;
    } else {
      // Set the lead form status to "warning" via Formik
      setStatus("warning");

      postEvent("warning", {
        error: {
          error_message: `User has submitted an address not verified by Smarty Autocomplete; warning message shown`,
          error_object: "Lead Form",
          error_type: "smarty_validation",
          path: window.location.href,
          invalid_address: formValues.oneLineAddress,
        },
      });

      errorMessage =
        "The entered address appears to be invalid.  Please verify it is correct.";
      await sleep(300);
    }

    return errorMessage;
  }

  return (
    <>
      <Input type="hidden" name="streetAddress" id="street-address" />
      <Input type="hidden" name="city" id="city" />
      <Input type="hidden" name="state" id="state" />
      <Input type="hidden" name="zipCode" id="zip-code" />
      <Fieldset
        className={clsx({
          "!rounded-b-none": displaySuggestions,
        })}
        dialog="We request your address for three main reasons: to verify your identity, to conduct an accurate soft credit pull without affecting your score, and to match you with the best financial solutions. Your privacy is paramount to us; all data is encrypted and used with the utmost care."
        legend={legend}
        ref={wrapperRef}
      >
        <Field
          className={clsx({
            "!rounded-b-none": displaySuggestions,
          })}
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={FIELD_NAME_SELECTOR}
          label="Address"
          validate={addressValidation}
        >
          <Input
            className={clsx({
              "!rounded-b-none": displaySuggestions,
            })}
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            name={FIELD_NAME_SELECTOR}
            ref={inputRef}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              queryAutocompleteForSuggestions(e.target.value, e)
            }
            autoComplete="off"
          />
        </Field>
        {displaySuggestions && (
          <Suggestions
            isOpen={displaySuggestions}
            ariaControlsId={INPUT_ID_AND_LABEL_HTMLFOR}
            suggestions={suggestions}
            selectSuggestion={selectSuggestion}
          />
        )}
      </Fieldset>
      <Button
        // This is necessary to prevent default form submission behavior
        type="button"
        onClick={onSubmitAddress}
      />
    </>
  );
}
