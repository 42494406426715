"use client";

import Script from "next/script";

import { NEVERBOUNCE_PUBLIC_API_KEY } from "@shared/constants";

import getVisitCookie from "@client/cookies/getVisitCookie";

import Visit from "@packages/types/visit";

export function Neverbounce() {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  return visitCookie?.neverbounceEnabled ? (
    <>
      <Script id="neverbounce" type="text/javascript">
        {`_NBSettings = {
            ajaxMode: true,
            apiKey: "${NEVERBOUNCE_PUBLIC_API_KEY}",
            apiOnly: true,
            rejectedMessage: "Please enter a valid email",
            displayPoweredBy: false,
            feedback: false,
            inputLatency: 300,
        }`}
      </Script>
      <Script
        type="text/javascript"
        src="https://cdn.neverbounce.com/widget/dist/NeverBounce.js"
      ></Script>
    </>
  ) : (
    <></>
  );
}
