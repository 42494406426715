import clsx from "clsx";
import React, { forwardRef } from "react";

import Label from "@ui/js/components/form/label";

type Props = {
  children: React.ReactNode;
  className: string;
  id?: string;
  label?: string;
  message?: string | TrustedHTML;
  state?: "info" | "warning" | "error";
  hasAutoComplete?: boolean;
};

const Field = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      id,
      className = "",
      label,
      message,
      state,
      hasAutoComplete = false,
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          {
            form__field: true,
            form__field_state_error: state === "error",
            form__field_state_info: state === "info",
            form__field_state_warning: state === "warning",
          },
          className,
        )}
      >
        {label && <Label htmlFor={id} label={label} />}
        {children}
        {message && (
          <span
            className={clsx(
              {
                form__field__message: true,
              },
              state !== undefined && hasAutoComplete ? "!rounded-b-none" : "",
              className,
            )}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      </div>
    );
  },
);

Field.displayName = "Field";

export default Field;
