import clsx from "clsx";
import React, { forwardRef } from "react";

import camelCaseToKebab from "@shared/functions/camelCaseToKebab";

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, "name"> & {
  name: string;
};

const Input = forwardRef<HTMLInputElement, Props>(
  ({ className = "", type, ...props }, ref) => {
    type = type || "text";

    if (!props.id) {
      props.id = camelCaseToKebab(props.name);
    }

    return (
      <input
        ref={ref}
        type={type}
        className={clsx(`form__input form__input_type_${type}`, className)}
        required={true}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

export default Input;
