"use client";

import { useFormikContext } from "formik";

import { logError } from "@shared/functions/log";

import validateEmail from "@server/neverbounce/validateEmail";

import { Neverbounce } from "@client/components/analytics/components/neverbounce";
import Field from "@client/components/formik/field";
import Input from "@client/components/formik/input";
import Button from "@client/components/lead-form/components/button";
import Fieldset from "@client/components/lead-form/components/fieldset";
import getVisitCookie from "@client/cookies/getVisitCookie";

import type Visit from "@packages/types/visit";

const INPUT_ID_AND_LABEL_HTMLFOR = "email";

export default function Email() {
  const visitCookie = getVisitCookie() as Visit.Cookie;

  interface FormValues {
    email: string;
    [key: string]: string | boolean;
  }
  const {
    isValid,
    submitCount,
    setFieldValue,
    setStatus,
    submitForm,
    validateForm,
    values,
  } = useFormikContext<FormValues>();

  function onSubmitEmail() {
    // This is necessary to ensure that the validEmail field is updated before submitting the form
    // setFieldValue() does not work inside Formik's async validation
    validateForm()
      .then(() => {
        if (submitCount === 0) {
          if (isValid) {
            setFieldValue("validEmail", "true");
          } else {
            setFieldValue("validEmail", "false");
          }
        } else if (submitCount > 0) {
          setFieldValue("validEmail", "");
        }

        submitForm();
      })
      .catch((error) => {
        setFieldValue("validEmail", "");

        logError({
          message: "A Formik error occurred while validating the form.",
          error,
          event: {
            destination: "Formik",
            name: "validateForm",
            meta: {
              formValues: values,
            },
          },
        });

        submitForm();
      });
  }

  async function emailValidation(emailAddress: string): Promise<string> {
    // The error message to display to the user
    let errorMessage = "";

    // Reset the lead form status via Formik
    setStatus("");

    /**
     * Skip Neverbounce validation if:
     *  - The form has been submitted more than once.
     *  - Neverbounce is not enabled.
     */
    if (submitCount > 0 || !visitCookie.neverbounceEnabled) {
      return ""; // No validation error
    }

    const validEmail = await validateEmail(emailAddress);

    if (validEmail === true) {
      errorMessage = ""; // No validation error
    } else if (validEmail === false) {
      setStatus("warning");

      errorMessage =
        "The entered email appears to be invalid, please verify it is correct.";
    } else {
      setStatus("error");

      errorMessage =
        "An error occurred while validating the email address. Please try again.";
    }

    return errorMessage;
  }
  return (
    <>
      <Input type="hidden" name="agreesToDataSharing" />
      <Input type="hidden" name="agreesToSoftPull" />
      <Input type="hidden" name="agreesToTCPA" id="agrees-to-tcpa" />
      <Input type="hidden" name="validEmail" id="valid-email" value="" />
      <Fieldset
        dialog="We request your email to provide essential updates, timely communication, and access to your personalized debt solutions. We're committed to not spamming and keeping your email information protected."
        legend="What is your email?"
      >
        <Field
          id={INPUT_ID_AND_LABEL_HTMLFOR}
          name={INPUT_ID_AND_LABEL_HTMLFOR}
          label="Email"
          validate={emailValidation}
        >
          <Input
            id={INPUT_ID_AND_LABEL_HTMLFOR}
            type="email"
            name={INPUT_ID_AND_LABEL_HTMLFOR}
            className="w-full"
          />
        </Field>
      </Fieldset>
      <Button
        // This is necessary to prevent default form submission behavior
        type="button"
        onClick={onSubmitEmail}
      />
      <Neverbounce />
    </>
  );
}
