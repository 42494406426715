import * as SmartySDK from "smartystreets-javascript-sdk";

import { SMARTY_EMBEDDED_KEY } from "@shared/constants";

export type ValuesType = {
  oneLineAddress: string;
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
  streetLine?: string;
  zipcode?: string;
  secondary?: never;
  entries?: never;
};

const SmartyCore = SmartySDK.core;
const smartySharedCredentials = new SmartyCore.SharedCredentials(
  SMARTY_EMBEDDED_KEY,
);

export const client = new SmartyCore.ClientBuilder(smartySharedCredentials)
  .withLicenses(["us-autocomplete-pro-cloud"])
  .buildUsAutocompleteProClient();

export function createUSLookup(query: string, hasSecondaries: boolean) {
  const lookup = new SmartySDK.usAutocompletePro.Lookup(query);
  lookup.maxResults = 5;

  // Specifies the percentage of address suggestions that should be preferred (in this case, those using geolocation) and will appear at the top of the suggestion list.
  lookup.preferRatio = 40;
  if (hasSecondaries) {
    lookup.selected = query;
  }
  return lookup;
}

export function formatAutocompleteSuggestion(
  suggestion: SmartySDK.usAutocompletePro.Suggestion | ValuesType,
  multipleEntries: boolean = false,
) {
  const street = suggestion.streetLine ? `${suggestion.streetLine}` : "";
  const secondary = suggestion?.secondary ? ` ${suggestion.secondary}` : "";
  const entries = suggestion?.entries !== 0 ? ` (${suggestion.entries})` : "";
  const city = suggestion?.city ? `${suggestion.city}, ` : "";
  const state = suggestion?.state ? `${suggestion.state} ` : "";
  const zip = suggestion?.zipcode ? `${suggestion.zipcode}` : "";

  if (multipleEntries) {
    return street + secondary + entries + ", " + city + state + zip;
  } else {
    return street + secondary + ", " + city + state + zip;
  }
}
