"use client";

import { FieldAttributes, useField } from "formik";
import { forwardRef } from "react";

import Input from "@ui/js/components/form/input";

const FormikInput = forwardRef<HTMLInputElement, FieldAttributes<any>>(
  (props, ref) => {
    const [field] = useField(props);

    return <Input ref={ref} {...field} {...props} />;
  },
);

FormikInput.displayName = "FormikInput";

export default FormikInput;
